@import '../../../../scss/bhStyles/variables.module.scss';

@mixin button-font-weight {
  &-font-weight-bold {
    font-family: SemiBold, sans-serif;
  }

  &-font-weight-medium {
    font-family: Medium, sans-serif;
  }

  &-font-weight-regular {
    font-family: Regular, sans-serif;
  }
}

@mixin button-font-size {
  &-font-size-medium {
    font-size: $text-font-size-14;
    height: 1.125rem;

    .bs-svg-icon {
      height: 1.125rem;
      width: 1.125rem;
    }
  }

  &-font-size-small {
    font-size: $text-font-size-12;
    height: $spacing-4;

    .bs-svg-icon {
      height: $spacing-4;
      width: $spacing-4;
    }
  }

  &-font-size-inherit {
    font-size: inherit;
  }
}
