.accountWrapper {
  @media screen and (max-width: 1024px) {
    margin: 0px 11px;
  }
}

.account {
  max-width: 625px;
  .accountName {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 79px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    gap: 17px;
    padding: 0px 23px;
    align-items: center;
  }
  .mijnAccountTitle {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 93px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    padding: 0px 22px;
    align-items: center;
    font-size: 33px;
    font-weight: bold;
  }

  .personLogoWrapper {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    padding-left: 0px;
    background-color: $variant-color;
  }

  .userName {
    font-weight: 600;
    font-size: 16px;
  }
  .userPosition {
    font-size: 13px;
  }

  .customCollection {
    padding-top: 73px;
    @media screen and (min-width: 1024px) {
      padding-top: 85px;
    }
  }

  .liWrapper {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .collection-item {
      border-bottom: none;
    }
  }

  .collection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 0px solid transparent;
    min-height: calc(100vh - 150px);

    @media screen and (min-width: 1024px) {
      min-height: 622.5px;
    }

    .collection-item {
      @media screen and (min-width: 1024px) {
        border-bottom: none;
      }

      display: flex;
      flex-direction: row;
      padding-left: 10px !important;
      align-items: center;

      .secondary-content {
        display: flex;
        justify-content: right;
        width: 100%;
        top: 0px !important;
        position: relative !important;
        right: 10px !important;
      }
      .activePathnameWrapper {
        border-radius: 8px;
        background-color: $primary-color-contact;

        position: absolute;
        bottom: 10px;
        top: 10px;
        left: 0;
        right: 0;
        z-index: -1;
      }
      .title {
        cursor: pointer;
        min-width: 200px;
      }
      &.avatar {
        min-height: 62px !important;
        z-index: 2;
      }
      svg {
        color: $primary-color;
        margin-right: 10px;
      }
      a svg {
        color: $grey-normal;
        margin-right: -20px;
      }
    }
  }

  .badge {
    display: inline-block;
    border-radius: 100px;
    line-height: 0.6;
    font-size: 10px;
    padding: 5px;
    color: white;
    position: absolute;
    margin-left: 5px;
    margin-top: 3px;
    text-align: center;
  }
}
