// Border radii (in rem), as used in e.g. buttons
$border-radius-none: 0;
$border-radius-xs: 0.25rem;
$border-radius-small: 0.5rem;
$border-radius-medium: 0.75rem;
$border-radius-large: 1.125rem;
$border-radius-xl: 1.625rem;
$border-radius-full: 999rem;

:export {
  borderRadiusNone: $border-radius-none;
  borderRadiusXs: $border-radius-xs;
  borderRadiusSmall: $border-radius-small;
  borderRadiusMedium: $border-radius-medium;
  borderRadiusLarge: $border-radius-large;
  borderRadiusXl: $border-radius-xl;
  borderRadiusFull: $border-radius-full;
}
