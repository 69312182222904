@import '../../../../scss/bhStyles/variables.module.scss';

.bs-form-error-text {
  display: flex;
  align-items: center;
  font-size: $text-font-size-12;
  color: $alert-regular;
  margin-left: $spacing-3;
  margin-top: $spacing-1;

  .bs-svg-icon {
    height: 0.6875rem;
    width: 0.6875rem;
    fill: $alert-regular;
    margin-right: $spacing-1;
  }
}
