@import '../../../../scss/bhStyles/variables.module.scss';

.bs-toggle-panel-group {
  .bs-radio-group {
    border-radius: $border-radius-small;
    background-color: $light-gray;
    padding: $spacing-1;
    column-gap: $spacing-1;
  }
  &-auto-width .bs-radio-group {
    .bs-toggle-panel {
      padding-left: $spacing-5;
      padding-right: $spacing-5;
      box-sizing: border-box;
    }

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: fit-content;
    box-sizing: content-box;
  }
  .bs-toggle-panel-group-label {
    display: inline-block;
    font-size: $text-font-size-18;
    font-family: SemiBold, sans-serif;
    margin-bottom: $spacing-2;
  }
}

.bs-toggle-panel-group-small {
  .bs-radio-group {
    height: $spacing-8;
  }
}
.bs-toggle-panel-group-medium {
  .bs-radio-group {
    height: $spacing-12;
  }
}
.bs-toggle-panel-group-large {
  .bs-radio-group {
    height: $spacing-15;
  }
}
