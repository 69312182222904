@import '../../../../../scss/bhStyles/variables.module.scss';

.main {
  margin-top: $spacing-8;
  .icon {
    fill: $primary-regular;
  }
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.add-img-wrapper {
  max-width: calc(50% - $spacing-3);
  @media screen and (max-width: $breakpoint-large) {
    max-width: 100%;
  }
}

.img-wrapper-add {
  border: 1px dashed $semi-dark-gray;
  border-radius: $border-radius-small;
  display: flex;
  height: 4.375rem;
  justify-content: center;
  align-items: center;
}

.img-wrapper-upload {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 15.625rem;
  position: relative;
  width: calc(50% - $spacing-3);
  height: 100%;
  border-radius: $border-radius-medium;
  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
  }

  :global(img) {
    height: 100%;
    max-height: calc(15.625rem - $spacing-10);
    object-fit: cover;
  }
}
.description {
  margin-top: $spacing-2;
  color: $semi-dark-gray;
}

.img-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $spacing-10;
  bottom: 0;
  width: 100%;
  color: $primary-regular;
  background: $primary-xxl;
}
