@import '../../../../scss/bhStyles/variables.module.scss';

.bs-btn-group {
  display: flex;
  align-items: center;
  column-gap: $spacing-4;
  row-gap: $spacing-4;

  // flex direction styling
  &-vertical {
    flex-direction: column;
  }
  &-vertical-reverse {
    flex-direction: column-reverse;
  }

  // spacing styling
  &-dense {
    column-gap: $spacing-3;
    row-gap: $spacing-3;
  }
  &-spacious {
    column-gap: $spacing-5;
    row-gap: $spacing-5;
  }

  // placement styling
  &-start {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-end {
    justify-content: flex-end;
  }
}
