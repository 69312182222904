.login,
.register {
  padding-top: 160px;
  img {
    width: 50%;
    float: right;
    display: block;
    margin-right: -15px;
    margin-top: -180px;
  }
}
