#override-Search-Results {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.search-results {
  .card {
    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .result-card-row {
    display: flex;
  }

  .top-row {
    &:first-child {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .filter-icon {
      display: flex;
      align-items: center;
      margin-right: 10px;
      svg {
        background: lighten($secondary-color, 45%);
        border-radius: 200px;
        padding: 4px;
        font-size: 38px;
      }
    }
    svg {
      color: $secondary-color;
      font-size: 30px;
    }
  }

  .result-card-container {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
  }

  .result-card {
    padding: 0;
    margin: 8px;
    .container {
      width: 150px;
      border-radius: 8px;
      box-shadow: $default-box-shadow;
      background: $white;
    }
    .favorite-icon {
      color: $white;
      position: absolute;
      margin-top: 10px;
      margin-left: 10px;
    }
    .product-image {
      width: -webkit-fill-available;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .season {
      display: inline-block;
      padding-bottom: 5px;
      div {
        display: inline-block;
        position: absolute;
        margin-left: 6px;
        color: $grey-normal;
      }
    }

    .season-image {
      width: 18px;
      height: 18px;
      margin-left: 10px;
      filter: invert(0.7);
    }

    .add-to-cart-icon {
      background: lighten($primary-color, 45%);
      border-radius: 200px;
      padding: 10px;
      font-size: 38px;
      color: $primary-color;
      margin-right: 30px;
      float: right;
      margin-top: -27px;
      width: 42px;
      height: 42px;
    }
  }

  .MuiChip-root {
    margin-right: 10px;
  }
}

.MuiChip-deletable {
  margin-right: 10px;
  background: $white !important;
  svg {
    color: $grey-normal;
    font-size: 20px;
  }
  box-shadow: $default-box-shadow;
  border: 0px solid transparent !important;
}

/* Desktop */
.desktop-page-container .search-results {
  @include desktop-page-margins;
  @include desktop-content-margins;
  .card:first-child {
    margin-left: 0;
    width: 100%;
  }
  .results-label {
    margin-top: 0px;
    .s8 {
      margin-left: -20px;
    }
  }
  .top-row {
    width: 100%;
    &:first-child {
      display: inline-block;
    }
    .col {
      display: inline-block;
    }
  }

  .result-card > .container {
    margin: 0;
    width: 100%;
  }
}
