@import '../../../../scss/bhStyles/variables.module.scss';

.bs-spinner {
  width: $spacing-10;
  height: $spacing-10;

  svg {
    animation: rotate 1.5s linear infinite;
    position: relative;
    width: inherit;
    height: inherit;
    display: inline-block;
    z-index: 1;

    circle {
      position: absolute;
      stroke: $primary-regular;
      stroke-linecap: round;
      animation: dash 2s ease-in-out infinite;

      stroke-width: 0.3125rem;

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }

        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }

        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  &-secondary {
    svg {
      circle {
        stroke: $secondary-regular;
      }
    }
  }

  &-neutral {
    svg {
      circle {
        stroke: $dark-gray;
      }
    }
  }

  &-backdrop {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 0.25rem solid $semi-light-gray;
      border-radius: 50%;
      background-color: transparent;
      display: inherit;
      z-index: 0;
    }
  }

  &-xs {
    width: $spacing-4;
    height: $spacing-4;

    &.bs-spinner-backdrop {
      &::after {
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border: 0.1rem solid $semi-light-gray;
      }
    }
  }

  &-small {
    width: $spacing-6;
    height: $spacing-6;

    &.bs-spinner-backdrop {
      &::after {
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border: 0.15rem solid $semi-light-gray;
      }
    }
  }

  &-large {
    width: $spacing-15;
    height: $spacing-15;

    &.bs-spinner-backdrop {
      &::after {
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        border: 0.37rem solid $semi-light-gray;
      }
    }
  }
}
