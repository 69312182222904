@import '../../../scss/bhStyles/variables.module.scss';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing-10;
  height: 50%;
  width: 100%;
  padding: 0 $spacing-10;
  margin-bottom: $spacing-15;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.block {
  display: flex;
  flex-direction: column;
}

.extra-info-wrapper,
.extra-info-wrapper-mobile {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }
}

.extra-info-wrapper-mobile {
  margin-top: 2rem;
}

.key {
  color: #949494;
  min-width: 6.5rem;
}

.value {
  font-weight: 500;
}
