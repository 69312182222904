@import '../FormInputText/form-input-infield-label';

.bs-form-basic-select {
  cursor: initial;

  .bs-form-label {
    pointer-events: none;
  }

  input {
    cursor: initial;
  }

  &-dropdown-open {
    .bs-form-label {
      color: $primary-regular !important;
    }
  }

  .bs-form-input-wrapper {
    .bs-form-input {
      &::placeholder {
        color: $semi-black !important;
      }
    }
  }

  .bs-form-input-label-wrapper {
    width: 100%;
  }

  &-label {
    @include form-input-infield-label();
  }
}
