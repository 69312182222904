@import '../../../../scss/bhStyles/variables.module.scss';
@import '../Button/button-appearance';

@mixin icon-button-size($size, $iconSize) {
  height: $size;
  padding: calc(($size - $iconSize) / 2);

  .bs-svg-icon {
    height: $iconSize;
    width: auto;
  }
}

@mixin icon-clear-button-size($iconSize) {
  height: $iconSize;

  .bs-svg-icon {
    height: $iconSize;
    width: auto;
  }
}

.bs-btn-icon {
  // save parent selector for later use
  $p: &;
  width: fit-content;

  @include button-appearance-default;

  &:hover {
    cursor: pointer;
    z-index: 0;
    @include button-transition;
  }

  &:active {
    transform: scale(0.97);
    @include button-transition;
  }

  &:disabled {
    cursor: not-allowed;
    transform: scale(1);
    text-decoration: none;

    &:after {
      background-color: transparent;
    }
  }

  &:focus {
    background-color: transparent !important;
  }

  .bs-tooltip {
    font-family: Regular, sans-serif;
  }

  // default sizes, except appearance clear
  &:not(&-clear) {
    // size medium
    @include icon-button-size($spacing-12, $spacing-6);
  }

  // appearance and default height for appearance clear
  &-filled {
    @include button-appearance-filled;
  }

  &-hollow {
    @include button-appearance-hollow;
  }

  &-tinted {
    @include button-appearance-tinted;
  }

  &-clear {
    @include button-appearance-clear;

    // default size small
    @include icon-clear-button-size($spacing-5);
  }

  // flat Side
  &-flat-left {
    @include button-flat-side-left;
  }

  &-flat-right {
    @include button-flat-side-right;
  }

  &-ghost {
    @include button-appearance-ghost;

    &.bs-btn-icon-size-custom,
    &.bs-btn-icon-size-custom-small {
      position: relative;
      padding: 0;

      &::after {
        content: '';
        position: absolute;
        top: -$spacing-1;
        bottom: -$spacing-1;
        left: -$spacing-1;
        right: -$spacing-1;
        border-radius: $border-radius-small;
        z-index: -1;
      }

      &:hover {
        &::after {
          background-color: $light-gray;
        }
      }
    }
  }

  // sizes
  &-filled,
  &-hollow,
  &-tinted,
  &-ghost {
    &#{$p}-size {
      &-large {
        @include icon-button-size($spacing-15, $spacing-6);
      }

      &-small {
        @include icon-button-size($spacing-8, $spacing-5);
      }

      &-xs {
        @include icon-button-size($spacing-6, $spacing-5);
        border-radius: $border-radius-xs;

        &:before {
          border-radius: $border-radius-xs;
        }
      }

      &-xxs {
        @include icon-button-size($spacing-5, $spacing-4);
        border-radius: $border-radius-xs;

        &:before {
          border-radius: $border-radius-xs;
        }
      }

      &-custom {
        height: max-content;

        &-small {
          padding: 0;
          height: max-content;
          border-radius: $border-radius-xs;
          &::after {
            border-radius: $border-radius-xs;
          }
        }
      }
    }
  }

  &-clear {
    &#{$p}-size {
      &-large {
        @include icon-clear-button-size($spacing-8);
      }

      &-medium {
        @include icon-clear-button-size($spacing-6);
      }

      &-xs {
        @include icon-clear-button-size($spacing-4);
      }
    }
  }
}
