.product-detail {
  .card-container {
    padding: 0;
  }

  .react-swipeable-view-container {
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

.mobileSliderWrapperParent .rsis-image {
  border-radius: 10px 10px 0px 0px;
}

.desktopSliderWrapperParent .rsis-image {
  border-radius: 10px;
}
