@import '../../../../scss/bhStyles/variables.module.scss';

.bs-form-inline-edit {
  &-container {
    position: relative;
  }

  &-buttons {
    position: absolute;
    right: 0;
    z-index: 10;
    background-color: $white;
    padding-top: $spacing-1;

    display: flex;
    justify-content: flex-end;
    gap: $spacing-1;

    .bs-btn-icon {
      box-shadow: $shadow-small;
    }
  }

  .bs-form-error-text {
    width: calc(100% - 5.2rem);
    margin: 0;
    padding: $spacing-1 $spacing-2 0;
  }
}
