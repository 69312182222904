@import '../../../../scss/bhStyles/variables.module.scss';

@mixin dropdown-divider {
  margin: $spacing-2 0;
  position: relative;
  z-index: 1;
  border-radius: 0;
  left: -$spacing-2;
  right: 0;
  width: calc(100% + $spacing-4);
}
