// Navbar

nav {
  background-color: $navbar-background-color;

  .nav-wrapper {
    .navbar-title {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .MuiSvgIcon-root {
      height: 35px;
      width: 35px;
      margin-top: 10px;
      margin-bottom: -11px;
    }
  }

  .nav-content {
    .icons {
      display: flex;
      float: right;
      margin-top: -48px;
      margin-right: 15px;
      width: fit-content;
    }
  }
}

// Navbar mobile menu
@media only screen and (max-width: 1023px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  .sidenav-trigger {
    display: block !important;
  }
}

.navbar-fixed-mobile {
  @media screen and (max-width: 601px) {
    height: 56px;
  }

  @media screen and (min-width: 601px) and (max-width: 1023px) {
    height: 64px;
  }
}

.nav-extended {
  @media screen and (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
}

.navbar-mobile-custom-container {
  position: relative;
}

#mobile-nav {
  li {
    padding-bottom: 15px;
    a {
      span {
        margin-left: 4rem;
      }

      .MuiSvgIcon-root {
        font-size: 2.5rem;
        margin-top: 0.5rem;
        position: absolute;
        padding-left: -4rem;
        &.close-button {
          margin-left: -1.8rem;
        }
      }
    }
  }
}

.navBarCloseIcon {
  color: $primary-color;
  font-size: 2.5rem;
  height: 48px;
  display: flex;
  align-items: center;
}

.close-button {
  color: $primary-color;
  font-size: 2.5rem;
  cursor: pointer;
  margin-left: 5px;
}

.toevoegen-back {
  position: fixed;
  left: 0;
  top: 0;
  color: #136ce2;
  align-items: center;
  padding-left: 12px;
  min-height: 56px;
  z-index: 5;

  @media screen and (min-width: 601px) {
    min-height: 64px;
  }
}

.toevoegen-icon {
  font-size: 17px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: #0b4188;
  }
}

.toevoegen-text {
  font-size: 16.4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: #0b4188;
  }
}

/* Desktop navbar */
.desktop-nav {
  .desktop-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 64px;
    margin-top: 0.5px;

    img {
      height: 32px;
    }
    margin-right: 25px;

    @media screen and (max-width: 1170px) {
      margin-right: 0px;
    }
  }

  ul {
    margin-left: 9%;
    @media screen and (max-width: 1300px) {
      margin-left: 5%;
    }

    @media screen and (max-width: 1150px) {
      margin-left: 15px;
    }
    .MuiSvgIcon-root {
      height: 20px;
      width: 20px;
      margin-bottom: -4px;
      margin-right: 5px;
    }
    a {
      padding-right: 25px;
      padding-left: 25px;
      font-weight: bold;
    }
  }

  .nav-content .icons {
    margin-right: 9.67%;

    @media screen and (max-width: 1300px) {
      margin-right: 5.9%;
    }
    @media screen and (max-width: 1150px) {
      margin-right: 31px;
    }
  }

  .personIconWrapper {
    width: 35px;
    height: 35px;

    margin-right: 9px;
    position: relative;
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $primary-color-contact;
  }
  .navbar-bestellingen-badge {
    position: absolute;
    top: -3px;
    margin-left: 27px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    background-color: $error-color;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 5px;
    line-height: 0.9;
  }
}

.navLinkNotActivePath {
  color: $grey-normal !important;
}

.navLinkActivePath {
  color: $primary-color !important;
}
