@import '../../../../scss/bhStyles/variables.module.scss';
@import '../../../../scss/variables';

@mixin form-input-border-base {
  position: relative;
  min-height: $spacing-15;
  background-color: $white;
  display: flex;
  align-items: center;
  cursor: text;
  border-radius: $border-radius-small;

  &:focus-visible {
    outline: none;
  }

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.0625rem solid $semi-light-gray;
    border-radius: $border-radius-small;
  }

  &:hover {
    &::after {
      border-color: $primary-regular;
    }
  }
}

@mixin form-input-border-focus {
  &:focus-within:not(&-disabled) {
    transition: all $animation-easing-regular $animation-speed-fast-1;
    &::after {
      border-color: $primary-regular;
      border-width: 0.125rem;
    }

    &.bs-form-input-border-error {
      &::after {
        border-color: $alert-regular;
        border-width: 0.125rem;
      }
    }

    &.bs-form-input-border-success {
      &::after {
        border-color: $success-regular;
        border-width: 0.125rem;
      }
    }
  }
}

@mixin form-input-border-status {
  &-error {
    &::after {
      border-color: $alert-regular;
    }

    &:hover {
      &::after {
        border-color: $alert-regular;
      }
    }
  }

  &-success {
    &::after {
      border-color: $success-regular;
    }
    &:hover {
      &::after {
        border-color: $success-regular;
      }
    }
  }

  &-disabled {
    background-color: $light-gray !important;
    cursor: not-allowed;

    &:hover {
      &::after {
        border-color: $semi-light-gray;
      }
    }

    &.bs-svg-icon {
      fill: $semi-dark-gray;
    }
  }
}

@mixin form-input-border-flat-side {
  &-flat-both {
    border-radius: 0;

    &:hover {
      &::after {
        z-index: 20;
      }
    }

    &:focus-within {
      &::after {
        z-index: 20;
      }
    }

    &::after {
      margin-right: -0.0625rem;
      border-radius: 0;
    }
  }

  &-flat-left {
    border-radius: 0 $border-radius-small $border-radius-small 0;
    &::after {
      border-radius: 0 $border-radius-small $border-radius-small 0;
    }
  }

  &-flat-right {
    border-radius: $border-radius-small 0 0 $border-radius-small;

    &:hover {
      &::after {
        z-index: 20;
      }
    }

    &:focus-within {
      &::after {
        z-index: 20;
      }
    }

    &::after {
      border-radius: $border-radius-small 0 0 $border-radius-small;
      margin-right: -0.0625rem;
    }
  }
}

@mixin form-input-border-size {
  &-small {
    min-height: $spacing-8;

    .bs-form-label {
      display: none;
    }

    .bs-form-input-wrapper {
      margin-top: 0 !important;
    }
  }

  &-medium {
    min-height: $spacing-12;

    .bs-form-label-shrink {
      z-index: 2;
      margin-top: $spacing-1 !important;
    }
  }
}

.bs-form-input-border {
  @include form-input-border-base();
  @include form-input-border-status();
  @include form-input-border-focus();
  @include form-input-border-size();
  @include form-input-border-flat-side();
}
