//Speed Animation

$animation-speed-slow-4: 2000ms;
$animation-speed-slow-3: 1000ms;
$animation-speed-slow-2: 800ms;
$animation-speed-slow-1: 500ms;
$animation-speed-regular: 300ms;
$animation-speed-fast-1: 200ms;
$animation-speed-fast-2: 100ms;
$animation-speed-fast-3: 50ms;

//Easing Animation
$animation-easing-regular: ease;
$animation-easing-out: cubic-bezier(0.3, 0.1, 0.5, 1);
$animation-easing-in: cubic-bezier(0.5, 0, 0.7, 0.9);
$animation-easing-bounce: cubic-bezier(0.5, 1.3, 0.6, 1);

//Delay Animation
$delay-speed-slow-2: 100ms;
$delay-speed-slow-1: 70ms;
$delay-speed-regular: 50ms;
$delay-speed-fast-1: 40ms;
$delay-speed-fast-2: 30ms;

//Presets
@mixin button-transition {
  transition: $animation-speed-fast-2 $animation-easing-regular;
}

:export {
  animationSpeedSlow4: $animation-speed-slow-4;
  animationSpeedSlow3: $animation-speed-slow-3;
  animationSpeedSlow2: $animation-speed-slow-2;
  animationSpeedSlow1: $animation-speed-slow-1;
  animationSpeedRegular: $animation-speed-regular;
  animationSpeedFast1: $animation-speed-fast-1;
  animationSpeedFast2: $animation-speed-fast-2;
  animationSpeedFast3: $animation-speed-fast-3;
  animationEasingRegular: $animation-easing-regular;
  animationEasingOut: $animation-easing-out;
  animationEasingIn: $animation-easing-in;
  animationEasingBounce: $animation-easing-bounce;
  delaySpeedSlow2: $delay-speed-slow-2;
  delaySpeedSlow1: $delay-speed-slow-1;
  delaySpeedRegular: $delay-speed-regular;
  delaySpeedFast1: $delay-speed-fast-1;
  delaySpeedFast2: $delay-speed-fast-2;
}
