.register {
  .address-row {
    padding: 0;
    margin-bottom: 0;
    .col {
      padding: 0;
      margin-bottom: 0;
      margin-top: 3px;
    }
    .s5 {
      padding-left: 10px;
    }
  }
}
