@import '../../../../scss/bhStyles/variables.module.scss';

.bs-tooltip {
  display: inline-flex;
  border-radius: $border-radius-small;
  box-shadow: $shadow-large;
  box-sizing: border-box;
  z-index: 10;

  &-arrow,
  &-arrow::before {
    position: absolute;
    width: $spacing-4;
    height: $spacing-4;
    z-index: 0;
  }

  &-arrow {
    visibility: hidden;
  }

  &-arrow::before {
    content: '';
    visibility: visible;
    box-shadow: $shadow-small;
    transform: rotate(45deg);
    background-color: $semi-dark-gray;
  }

  // use placement attribute to make sure the arrow flips correctly
  &[data-popper-placement^='top'] > &-arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > &-arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > &-arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > &-arrow {
    left: -4px;
  }

  &-container {
    position: relative;
    border-radius: $border-radius-small;
    background-color: $semi-dark-gray;
    font-family: Regular, sans-serif;
    font-size: $text-font-size-16;
    padding: $spacing-2;
    color: $white;
    z-index: 10;
  }
}
