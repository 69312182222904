@import '../../../../scss/bhStyles/variables.module.scss';

@mixin checkbox-hover {
  &-inner:not(&-inner-disabled):hover {
    border-color: $semi-dark-gray;
  }

  &-inner-checked:not(&-inner-disabled):hover,
  &-inner-indeterminate:not(&-inner-disabled):hover {
    &::after {
      background-color: $primary-dark;
    }
  }
}

@mixin checkbox-base {
  border-radius: $border-radius-xs;

  &-label {
    column-gap: $spacing-2;
  }

  &-inner {
    position: relative;
    width: 100%;
    height: $spacing-5 !important;
    border: 2px $medium-gray solid;
    padding-left: 0 !important;
    border-radius: $border-radius-xs;
    &:after {
      content: '' !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      border-radius: $border-radius-xs !important;
    }
    &::before {
      content: none !important;
    }

    &-checked,
    &-indeterminate {
      border: none;
      &:after {
        background-color: $primary-regular;
        mask-size: contain;
      }
    }

    &-checked {
      &:after {
        mask: url('./checkbox-on.svg') no-repeat;
        transform: scale(1) !important;
        width: $spacing-5 !important;
        height: $spacing-5 !important;
        margin-top: 0 !important;
      }
    }

    &-indeterminate {
      &:after {
        mask: url('./checkbox-indeterminate.svg') no-repeat;
      }
    }

    &-disabled {
      border-color: $semi-light-gray;
      &.bs-checkbox-inner-checked,
      &.bs-checkbox-inner-indeterminate {
        &:after {
          background-color: $semi-light-gray;
        }
      }
    }
  }

  &-wrapper {
    overflow: hidden;
    position: initial;
    display: inline-flex;
    width: $spacing-5;
    height: $spacing-5;
    box-sizing: border-box;
  }

  &-input {
    display: none;
    margin: 0;
    width: $spacing-5;
    height: $spacing-5;
  }
}

.bs-checkbox {
  display: inline-flex;

  // generic styling for checkbox and chip
  &-label {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    color: $type-default !important;
    font-size: $text-font-size-16 !important;

    &-disabled {
      cursor: not-allowed;
    }
  }

  // specific styling for checkbox
  @include checkbox-base();
  @include checkbox-hover();
}
