@import '../../../../scss/bhStyles/variables.module.scss';
@import '../Button/button-appearance';
@import '../Button/button-font';

.bs-anchor {
  text-decoration: none;
  @include button-appearance-default;

  padding: 2px $spacing-4;
  column-gap: $spacing-1;

  &:hover {
    cursor: pointer;
    @include button-transition;
  }

  &:active {
    transform: scale(0.97);
    @include button-transition;
  }

  &-disabled {
    cursor: not-allowed;
    transform: scale(1);
    text-decoration: none;

    &:after {
      background-color: transparent;
    }
  }

  .bs-anchor-adornment {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bs-svg-icon {
      height: $spacing-5;
      width: auto;
    }
  }

  // appearance
  &-filled {
    @include button-appearance-filled;
  }

  &-hollow {
    @include button-appearance-hollow;
  }

  &-tinted {
    @include button-appearance-tinted;
  }

  &-clear {
    @include button-appearance-clear;
  }

  &-ghost {
    @include button-appearance-ghost;
  }

  &-link {
    @include button-appearance-link;
  }

  // flat Side
  &-flat-left {
    @include button-flat-side-left;
  }

  &-flat-right {
    @include button-flat-side-right;
  }

  // sizes
  &-size {
    &-large {
      height: $spacing-15;
      padding-left: $spacing-5;
      padding-right: $spacing-5;
    }

    &-small {
      height: $spacing-8;
    }
  }

  &-fullwidth {
    width: 100%;
    box-sizing: border-box;
  }

  // font weight for appearance clear and link
  @include button-font-size();
  @include button-font-weight();
}
