$z-index-app-bar: 1100;
$z-index-side-bar: 1200;
$z-index-modal: 1300;
$z-index-snackbar: 1400;
$z-index-tooltip: 1500;

:export {
  zIndexAppBar: $z-index-app-bar;
  zIndexSideBar: $z-index-side-bar;
  zIndexModal: $z-index-modal;
  zIndexSnackbar: $z-index-snackbar;
  zIndexTooltip: $z-index-tooltip;
}
