@import '../../../../scss/bhStyles/variables.module.scss';

.bs-divider {
  border: 0;
  border-radius: $border-radius-full;
  margin: 0;

  &-semi-light-gray {
    background-color: $semi-light-gray;
  }

  &-primary {
    background-color: $primary-regular;
  }

  &-vertical {
    height: 100%;

    &.bs-divider-normal {
      width: 0.0625rem;
    }

    &.bs-divider-bold {
      width: 0.1875rem;
    }
  }

  &-horizontal {
    width: 100%;

    &.bs-divider-normal {
      height: 0.0625rem;
    }

    &.bs-divider-bold {
      height: 0.1875rem;
    }
  }
}
