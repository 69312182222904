// Rules for the entire app
@import '../app/components/bhComponents/fonts/mulish/mulish.module';

html {
  font-size: 16px;
}

// mixins
@mixin desktop-page-margins {
  margin-left: calc(10% + 15px);
  margin-right: 10%;
}

@mixin desktop-content-margins {
  margin-top: 5%;
}

body {
  background-color: $grey-light;
  font-family: $font-stack;
}

element.style {
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]):focus {
  border: none;
  box-shadow: none;
}

li svg {
  color: $secondary-color;
}

button {
  text-transform: none !important;
  font-weight: bold;
}

button:focus {
  // background-color: $primary-color;
}

.btn-flat {
  color: $primary-color;
}

.bottom-buttons {
  display: flex;
  flex-direction: column;
}

.input-field {
  input {
    border: 1px solid $grey-normal !important;
    border-radius: 8px !important;
    text-indent: 15px;
    &:hover,
    &:focus {
      border: 1px solid $primary-color !important;
      box-shadow: 0 0 0 0 transparent !important;
    }
  }
  label {
    color: $grey-dark !important;
    &.active {
      color: $primary-color !important;
      background: #ffffff;
      padding: 5px;
      transition: all 0.2s;
    }
  }
  .select-wrapper ul {
    border-radius: 8px;
  }
}

a {
  color: $primary-color;
  transition: all $transition-time-default;
  &:hover {
    color: lighten($primary-color, 25%);
    transition: all $transition-time-default;
  }
}

h5,
h6 {
  font-weight: bold;
}

h5 {
  color: $primary-color;
  padding-bottom: 10px;
}

h6 {
  padding-top: 10px;
}

strong {
  font-weight: bold;
}

.grey-font {
  color: $grey-normal;
}

.primary-font {
  color: $primary-color;
}

.success-font {
  color: $success-color;
}

.error-font {
  color: $error-color;
}

.warning-font {
  color: $warning-color;
}

.active-font {
  color: $primary-color;
}

// sliding page transitions
.app {
  > div > .page-container {
    &.slide-enter {
      margin-left: 100vw;
      opacity: 0;
      position: absolute;
      top: 56px;
      .modal {
        left: -1000px;
      }
    }
    &.slide-enter-active {
      opacity: 1;
      margin-left: 0;
      transition: all $transition-time-default;
      transition-property: opacity, margin-left;
    }
    &.slide-enter-done {
      position: relative;
      .modal {
        position: absolute;
        left: auto;
      }
    }
    &.slide-exit {
      position: absolute;
      opacity: 1;
      margin-left: 0;
    }
    &.slide-exit-active {
      opacity: 0;
      transition: all $transition-time-default;
      transition-property: opacity, margin-left;
      margin-left: -100vw;
    }
  }
}

.row .col {
  float: none;
  flex: 1;
}

.MuiChip-deletable {
  box-shadow: none !important;
}
