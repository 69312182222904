@import '../../../../scss/bhStyles/variables.module.scss';

.toggle {
  column-gap: $spacing-3 !important;
  margin-top: $spacing-2;
  @media screen and (max-width: $breakpoint-large) {
    flex-wrap: wrap !important;
  }
}

.button {
  margin-top: 5rem;
  width: calc(50% - $spacing-3);
}
