.kenteken-input {
  margin-top: 2px;
  flex: 1;
  position: relative;

  .loaderPlaceholder {
    width: 40px;
    height: 47px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    background-color: $yellow-light;
    border: 2px solid $grey-dark;
    border-radius: 8px;

    .col {
      display: flex;
      align-items: center;

      &.s9 {
        width: 100%;
        margin-top: -49px;
      }

      input {
        border: none;
        max-width: 100%;
        text-align: center;
        padding-left: 40px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 22px;
        letter-spacing: 0.4rem;
        margin-top: 7px;

        &:hover {
        }
        &::placeholder {
          color: $yellow-dark;
          font-weight: bold;
          letter-spacing: 0.2rem;
        }
      }

      &:first-child {
        height: 44px;
        margin-left: -1px;
        display: block;
        width: 40px;
        background-color: #136ce2;
        border-radius: 6px 0px 0px 6px;
      }
    }

    .col-after {
      flex-direction: column;
      justify-content: center;
      height: 48px;
      span {
        text-transform: uppercase;
        font-size: $input-font-size;
        font-weight: bold;
      }
    }
  }
}
