@import '../../../../scss/bhStyles/variables.module.scss';
@import '../../../../scss/bhStyles/scrollbar.module.scss';
@import 'dropdown-base';
@import 'dropdown-divider';

.bs-dropdown {
  @include dropdown-base;
  @include scroll;
  @include scroll-no-track;

  .bs-divider {
    @include dropdown-divider;
  }
}
