$heading-font-size-48: 3rem;
$heading-font-size-40: 2.5rem;
$heading-font-size-36: 2.25rem;
$heading-font-size-32: 2rem;
$heading-font-size-28: 1.75rem;
$heading-font-size-24: 1.5rem;
$heading-font-size-20: 1.25rem;
$heading-font-size-18: 1.125rem;
$heading-font-size-16: 1rem;
$heading-font-size-14: 0.875rem;

$text-font-size-20: 1.25rem;
$text-font-size-18: 1.125rem;
$text-font-size-16: 1rem;
$text-font-size-14: 0.875rem;
$text-font-size-12: 0.75rem;
$text-font-size-10: 0.625rem;

:export {
  headingFontSize48: $heading-font-size-48;
  headingFontSize40: $heading-font-size-40;
  headingFontSize36: $heading-font-size-36;
  headingFontSize32: $heading-font-size-32;
  headingFontSize28: $heading-font-size-28;
  headingFontSize24: $heading-font-size-24;
  headingFontSize20: $heading-font-size-20;
  headingFontSize18: $heading-font-size-18;
  headingFontSize16: $heading-font-size-16;
  headingFontSize14: $heading-font-size-14;
  textFontSize20: $text-font-size-20;
  textFontSize18: $text-font-size-18;
  textFontSize16: $text-font-size-16;
  textFontSize14: $text-font-size-14;
  textFontSize12: $text-font-size-12;
  textFontSize10: $text-font-size-10;
}
