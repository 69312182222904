.price-label {
  font-size: 18px;
  margin-left: 10px;
  padding-bottom: 5px;
  .integer {
    font-weight: bold;
    font-size: 22px;
  }
  .fraction {
    font-size: x-small;
    position: absolute;
    margin-left: 2px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: bold;
  }
}
