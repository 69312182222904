.price-slider {
  .input-field {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    margin-bottom: 1px;
    .pricerange-min,
    .pricerange-max {
      // width: 100%;
    }
    .pricerange-min {
      border-radius: 8px 0px 0px 8px !important;
      border-right: transparent !important;
    }
    .pricerange-max {
      border-radius: 0px 8px 8px 0px !important;
      border-left: transparent;
    }
    input {
      padding-left: 10px;
      padding-right: 5px;
      box-sizing: border-box;
    }
  }

  .input-icon {
    position: relative;
    flex: 1;
  }

  .input-icon i {
    position: absolute;
    left: 10px;
    top: 10px;
    font-style: normal;
  }

  .MuiSlider-root {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1023px) {
    #slider--mui {
      padding: 0px 12px;
      overflow: visible;
    }
  }

  @media screen and (min-width: 1025px) {
    #slider--mui {
      padding: 0px 8px;
      overflow: visible;
    }
  }
}
