@import '../../../../scss/bhStyles/variables.module.scss';

.main {
  display: flex;
  justify-content: center;
  width: 100%;
}

// container tile styling
.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: $border-radius-medium;
  max-width: 66.5rem;
  box-shadow: $shadow-medium;
  padding: $spacing-15;
  margin: $spacing-15;
  @media screen and (max-width: $breakpoint-large) {
    padding: $spacing-10;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    margin: 0;
    max-width: 100vw;
  }
  @media screen and (max-width: $breakpoint-medium) {
    padding: $spacing-4;
    margin: 0;
  }
}

// top part styling img/text/buttons
.wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  height: 20.625rem;
  width: 100%;
  column-gap: $spacing-10;
  margin-bottom: $spacing-10;
  @media screen and (max-width: $breakpoint-large) {
    grid-template-columns: 1fr;
    height: fit-content;
  }
}

.img-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  @media screen and (max-width: $breakpoint-large) {
    aspect-ratio: 16 / 9;
    margin: 0 auto;
  }

  img {
    min-width: 100%;
    height: 100%;
  }
}

.info-wrapper {
  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
    padding-top: $spacing-5;
  }
}

.title {
  margin-bottom: $spacing-8;
  @media screen and (max-width: $breakpoint-medium) {
    font-size: $text-font-size-16;
    margin-bottom: $spacing-4;
  }
}

.attention {
  margin-top: $spacing-5;

  .anchor {
    display: flex;
    width: fit-content;
    margin-top: $spacing-3;
  }
}

.car-info {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $breakpoint-large) {
    order: 1;
  }
}

// bottom part styling list and warnings
.grid,
.grid-small {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing-10;
  row-gap: $spacing-10;
  width: 100%;
  @media screen and (max-width: $breakpoint-large) {
    grid-template-columns: 1fr;
  }
}

.grid-small {
  row-gap: 0;
}

.header-long,
.header {
  color: $primary-regular;
  margin-bottom: $spacing-4;
  @media screen and (max-width: $breakpoint-large) {
    font-size: $text-font-size-18;
  }
}

.header-long {
  grid-column-start: 1;
  grid-column-end: 3;
  @media screen and (max-width: $breakpoint-large) {
    grid-column-end: 2;
  }
}

.block {
  display: flex;
  flex-direction: column;
}
.list {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $breakpoint-large) {
    order: 2;
  }
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media screen and (max-width: $breakpoint-large) {
    height: calc(100vh - 95px);
  }
}

.flex-box {
  display: flex;
  column-gap: $spacing-2;
}

.price-label {
  display: flex;
  //height: $spacing-8;
  line-height: 2.5rem;
}
