@import '../../../../scss/bhStyles/variables.module.scss';

.bs-toggle-panel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-xs;
  width: 100%;
  height: 100%;
  transition: $animation-easing-regular $animation-speed-regular;
  label {
    font-family: Regular, sans-serif;
    font-size: $text-font-size-16;
  }
  .bs-radio-inner {
    display: none !important;
  }
  input {
    display: none;
  }

  label::before {
    content: attr(data-label);
    display: flex;
    font-family: SemiBold, sans-serif;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  label {
    color: $semi-black;
    font-family: Regular, sans-serif;
    cursor: pointer;
    text-align: center;
  }
  &:hover {
    background-color: $semi-light-gray;
  }

  &.bs-radio-checked {
    background-color: $white;
    label {
      color: $primary-regular;
      font-family: SemiBold, sans-serif;
    }
    &:hover {
      background-color: $white;
    }
  }
}
