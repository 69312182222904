@import '../../../../scss/bhStyles/variables.module.scss';

input.bs-form-input {
  height: $spacing-6;
  width: 100%;
  margin: 0 $spacing-3 0 $spacing-3;
  padding: 0;
  font-size: $text-font-size-16;
  font-family: Regular, sans-serif;
  border: none;
  text-overflow: ellipsis;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $medium-gray;
  }

  &:disabled {
    cursor: not-allowed;
    color: $semi-dark-gray;
    background-color: $light-gray;
    border: none;
  }
}
