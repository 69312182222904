@import '../../../../scss/bhStyles//variables.module.scss';

.bs-tg {
  &-heading,
  &-text {
    margin: 0;
    padding: 0;
    font-weight: unset;
  }

  &-heading {
    color: $semi-black;
    font-family: SemiBold, sans-serif;

    &-48 {
      font-size: $heading-font-size-48;
    }
    &-40 {
      font-size: $heading-font-size-40;
    }
    &-36 {
      font-size: $heading-font-size-36;
    }
    &-32 {
      font-size: $heading-font-size-32;
    }
    &-28 {
      font-size: $heading-font-size-28;
    }
    &-24 {
      font-size: $heading-font-size-24;
    }
    &-20 {
      font-size: $heading-font-size-20;
    }
    &-18 {
      font-size: $heading-font-size-18;
    }
    &-16 {
      font-size: $heading-font-size-16;
    }
    &-14 {
      font-size: $heading-font-size-14;
    }
  }

  &-text {
    color: $semi-black;
    font-family: Regular, sans-serif;
    &-20 {
      font-size: $text-font-size-20;
    }
    &-18 {
      font-size: $text-font-size-18;
    }
    &-16 {
      font-size: $text-font-size-16;
    }
    &-14 {
      font-size: $text-font-size-14;
    }
    &-12 {
      font-size: $text-font-size-12;
    }
    &-10 {
      font-size: $text-font-size-10;
    }
  }

  &-black {
    font-family: Black, sans-serif;
  }
  &-black-italic {
    font-family: BlackItalic, sans-serif;
  }
  &-extra-bold {
    font-family: ExtraBold, sans-serif;
  }
  &-extra-bold-italic {
    font-family: ExtraBoldItalic, sans-serif;
  }
  &-bold {
    font-family: Bold, sans-serif;
  }
  &-bold-italic {
    font-family: BoldItalic, sans-serif;
  }
  &-semi-bold {
    font-family: SemiBold, sans-serif;
  }
  &-semi-bold-italic {
    font-family: SemiBoldItalic, sans-serif;
  }
  &-medium {
    font-family: Medium, sans-serif;
  }
  &-medium-italic {
    font-family: MediumItalic, sans-serif;
  }
  &-regular {
    font-family: Regular, sans-serif;
  }
  &-italic {
    font-family: Italic, sans-serif;
  }
  &-light {
    font-family: Light, sans-serif;
  }
  &-light-italic {
    font-family: LightItalic, sans-serif;
  }
  &-extra-light {
    font-family: ExtraLight, sans-serif;
  }
  &-extra-light-italic {
    font-family: ExtraLightItalic, sans-serif;
  }
  &-thin {
    font-family: Thin, sans-serif;
  }
  &-thin-italic {
    font-family: ThinItalic, sans-serif;
  }
}
