@import '../../../../scss/bhStyles/variables.module.scss';

@mixin dropdown-base {
  background-color: $white;
  overflow-y: auto;
  overflow-x: hidden;
  padding: $spacing-2;
  border-radius: $border-radius-small;
  box-shadow: $shadow-small;
  z-index: $z-index-modal;
  position: relative;
  box-sizing: border-box;
}
