.dashboard-desktop {
  .checkmarks {
    li {
      display: inline-block;

      .MuiSvgIcon-root {
        margin-bottom: -5px;
        font-size: 20px;
        margin-left: 10px;
        margin-right: 5px;
      }
      margin-right: 10px;
    }
  }

  .filters {
    border-bottom: 1px solid $primary-color;
  }

  .filter-inputs {
    div {
      flex: 1;
    }
    div:first-child .input-field {
      padding-left: 0px;
    }
    div:last-child .input-field {
      margin-right: 15px;
    }
  }

  h4 {
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .three-col-input {
    margin-bottom: 20px;
    .kenteken-input {
      margin-right: 30px;
    }
    .input-field:last-child {
      padding-right: 0;
      margin-left: 30px;
    }
  }

  .form-submit-btn {
    width: 33%;
    float: right;
  }

  > .card {
    @include desktop-page-margins;
    &:first-child {
      @include desktop-content-margins;
    }
    padding-left: 60px;
    padding-right: 60px;
  }
}
