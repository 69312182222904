@import '../../../../scss/bhStyles/variables.module.scss';
@import './button-colors';

@mixin button-appearance-default {
  border-radius: $border-radius-small;
  display: inline-flex;
  border: none;
  position: relative;
  align-items: center;
  justify-content: center;

  // default font weight and size
  font-family: Semibold, sans-serif;
  font-size: $text-font-size-16;

  // default button size medium
  height: $spacing-12;

  // before is used to add a border
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: $border-radius-small;
    @include button-transition;
  }
  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    .bs-spinner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .bs-svg-icon {
      fill: transparent !important;
    }
    &:disabled {
      color: transparent !important;
    }
  }
}

@mixin button-appearance-filled {
  @include button-color-filled($primary-regular, $primary-dark, $primary-xd);
  @include button-color-filled-disabled();

  &:focus {
    @include button-color-filled($primary-regular, $primary-dark, $primary-xd);
  }

  &-alert {
    @include button-color-filled($alert-regular, $alert-dark, $alert-xd);

    &:focus {
      @include button-color-filled($alert-regular, $alert-dark, $alert-xd);
    }
  }

  &-warning {
    @include button-color-filled($warning-regular, $warning-dark, $warning-xd);

    &:focus {
      @include button-color-filled($warning-regular, $warning-dark, $warning-xd);
    }
  }

  &-success {
    @include button-color-filled($success-regular, $success-dark, $success-xd);

    &:focus {
      @include button-color-filled($success-regular, $success-dark, $success-xd);
    }
  }

  &-gray {
    @include button-color-filled($semi-dark-gray, $dark-gray, $semi-black);

    &:focus {
      @include button-color-filled($semi-dark-gray, $dark-gray, $semi-black);
    }
  }

  .bs-spinner {
    svg {
      circle {
        stroke: $white;
      }
    }
  }
}

@mixin button-appearance-hollow {
  @include button-color-hollow($primary-regular, $primary-dark, $primary-xd);
  @include button-color-hollow-disabled();

  &:focus {
    background-color: transparent !important;
    &:before {
      border-width: 2px;
    }
  }

  &-alert {
    @include button-color-hollow($alert-regular, $alert-dark, $alert-xd);
  }

  &-warning {
    @include button-color-hollow($warning-regular, $warning-dark, $warning-xd);
  }

  &-success {
    @include button-color-hollow($success-regular, $success-dark, $success-xd);
  }

  &-gray {
    @include button-color-hollow($semi-dark-gray, $dark-gray, $semi-black);
  }
}

@mixin button-appearance-tinted {
  @include button-color-tinted($primary-regular, $primary-dark, $primary-xd, $primary-xxl);
  @include button-color-tinted-disabled();

  &-alert {
    @include button-color-tinted($alert-regular, $alert-dark, $alert-xd, $alert-xxl);
  }

  &-warning {
    @include button-color-tinted($warning-regular, $warning-dark, $warning-xd, $warning-xxl);
  }

  &-success {
    @include button-color-tinted($success-regular, $success-dark, $success-xd, $success-xxl);
  }

  &-gray {
    @include button-color-tinted($semi-dark-gray, $dark-gray, $semi-black, $light-gray);
  }
}

@mixin button-appearance-clear {
  padding: 0;
  height: $spacing-5;

  @include button-color-clear($primary-regular, $primary-dark, $primary-xd);
  @include button-color-clear-disabled();

  &-alert {
    @include button-color-clear($alert-regular, $alert-dark, $alert-xd);
  }

  &-warning {
    @include button-color-clear($warning-regular, $warning-dark, $warning-xd);
  }

  &-success {
    @include button-color-clear($success-regular, $success-dark, $success-xd);
  }

  &-gray {
    @include button-color-clear($semi-dark-gray, $dark-gray, $semi-black);
  }
}

@mixin button-appearance-ghost {
  @include button-color-ghost($primary-regular, $primary-dark, $primary-xd);
  @include button-color-ghost-disabled();
  padding-left: $spacing-3;
  padding-right: $spacing-3;

  &-alert {
    @include button-color-ghost($alert-regular, $alert-dark, $alert-xd);
  }

  &-warning {
    @include button-color-ghost($warning-regular, $warning-dark, $warning-xd);
  }

  &-success {
    @include button-color-ghost($success-regular, $success-dark, $success-xd);
  }

  &-gray {
    @include button-color-ghost($semi-dark-gray, $dark-gray, $semi-black);
  }

  &.bs-btn-size-large {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
  }
}

@mixin button-appearance-link {
  padding-left: 0;
  padding-right: 0;
  height: $spacing-5;
  font-family: Regular, sans-serif;

  &:hover {
    text-decoration: underline;

    &:before {
      transition: none;
    }
  }

  &:active {
    transform: scale(1);
  }

  &:disabled {
    color: $medium-gray;

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }

  @include button-color-link($primary-regular, $primary-dark, $primary-light);
  @include button-color-link-disabled();

  &-alert {
    @include button-color-link($alert-regular, $alert-dark, $alert-light);
  }

  &-warning {
    @include button-color-link($warning-regular, $warning-dark, $warning-light);
  }

  &-success {
    @include button-color-link($success-regular, $success-dark, $success-light);
  }

  &-gray {
    @include button-color-link($semi-dark-gray, $dark-gray, $semi-black);
  }
}

@mixin button-flat-side-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@mixin button-flat-side-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
