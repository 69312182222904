@import '../../../../scss/bhStyles/variables.module.scss';

.bs-radio-group {
  display: flex;
  &-center {
    align-items: center;
    justify-content: center;
  }
  &-start {
    align-items: start;
    justify-content: start;
  }
  &-end {
    align-items: end;
    justify-content: end;
  }
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
  &-wrap {
    flex-wrap: wrap;
    row-gap: $spacing-2;
    column-gap: $spacing-8;
  }
  &-nowrap {
    flex-wrap: nowrap;
    row-gap: $spacing-2;
    column-gap: $spacing-8;
  }
}
