$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-7: 1.75rem;
$spacing-8: 2rem;
$spacing-9: 2.25rem;
$spacing-10: 2.5rem;
$spacing-11: 2.75rem;
$spacing-12: 3rem;
$spacing-13: 3.25rem;
$spacing-14: 3.5rem;
$spacing-15: 3.75rem;
$spacing-16: 4rem;
$spacing-18: 4.5rem;
$spacing-20: 5rem;
$spacing-24: 6rem;
$spacing-32: 8rem;

:export {
  spacing1: $spacing-1;
  spacing2: $spacing-2;
  spacing3: $spacing-3;
  spacing4: $spacing-4;
  spacing5: $spacing-5;
  spacing6: $spacing-6;
  spacing7: $spacing-7;
  spacing8: $spacing-8;
  spacing9: $spacing-9;
  spacing10: $spacing-10;
  spacing11: $spacing-11;
  spacing12: $spacing-12;
  spacing13: $spacing-13;
  spacing14: $spacing-14;
  spacing15: $spacing-15;
  spacing16: $spacing-16;
  spacing18: $spacing-18;
  spacing20: $spacing-20;
  spacing24: $spacing-24;
  spacing32: $spacing-32;
}
