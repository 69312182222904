@import '../../../../scss/bhStyles/variables.module.scss';

.bs-checkbox-group {
  display: flex;

  &.bs-checkbox-group-center {
    align-items: center;
    justify-content: center;
  }

  &.bs-checkbox-group-start {
    align-items: start;
    justify-content: start;
  }

  &.bs-checkbox-group-end {
    align-items: end;
    justify-content: end;
  }

  &.bs-checkbox-group-column {
    flex-direction: column;
  }

  &.bs-checkbox-group-row {
    flex-direction: row;
  }

  &.bs-checkbox-group-wrap {
    flex-wrap: wrap;
    row-gap: $spacing-2;
    column-gap: $spacing-8;
  }

  &.bs-checkbox-group-nowrap {
    flex-wrap: nowrap;
    row-gap: $spacing-2;
    column-gap: $spacing-8;
  }
}
