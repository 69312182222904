.stepper {
  .MuiButton-text {
    text-transform: none;
    font-size: 1.2rem;
    margin-top: 25vh;
    width: 200px;
  }

  .MuiMobileStepper-dots {
    margin-top: -10%;
  }

  .btn-hidden {
    opacity: 0;
    z-index: -1;
  }
}
