@font-face {
  font-family: 'Black';
  font-weight: 900;
  font-display: swap;
  src: url('./static/mulish-v13-latin-900.woff2') format('woff2'), url('./static/Mulish-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'BlackItalic';
  font-weight: 900;
  font-display: swap;
  src: url('./static/mulish-v13-latin-900italic.woff2') format('woff2'),
    url('./static/Mulish-BlackItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'ExtraBold';
  font-weight: 800;
  font-display: swap;
  src: url('./static/mulish-v13-latin-800.woff2') format('woff2'), url('./static/Mulish-ExtraBold.ttf') format('ttf');
}

@font-face {
  font-family: 'ExtraBoldItalic';
  font-weight: 800;
  font-display: swap;
  src: url('./static/mulish-v13-latin-800italic.woff2') format('woff2'),
    url('./static/Mulish-ExtraBoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Bold';
  font-weight: 700;
  font-display: swap;
  src: url('./static/mulish-v13-latin-700.woff2') format('woff2'), url('./static/Mulish-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'BoldItalic';
  font-weight: 700;
  font-display: swap;
  src: url('./static/mulish-v13-latin-700italic.woff2') format('woff2'),
    url('./static/Mulish-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'SemiBold';
  font-weight: 600;
  font-display: swap;
  src: url('./static/mulish-v13-latin-700.woff2') format('woff2'), url('./static/Mulish-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'SemiBoldItalic';
  font-weight: 600;
  font-display: swap;
  src: url('./static/mulish-v13-latin-600italic.woff2') format('woff2'),
    url('./static/Mulish-SemiBoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Medium';
  font-weight: 500;
  font-display: swap;
  src: url('./static/mulish-v13-latin-500.woff2') format('woff2'), url('./static/Mulish-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'MediumItalic';
  font-weight: 500;
  font-display: swap;
  src: url('./static/mulish-v13-latin-500italic.woff2') format('woff2'),
    url('./static/Mulish-MediumItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Regular';
  font-weight: 400;
  font-display: swap;
  src: url('./static/mulish-v13-latin-regular.woff2') format('woff2'), url('./static/Mulish-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Italic';
  font-weight: 400;
  font-display: swap;
  src: url('./static/mulish-v13-latin-italic.woff2') format('woff2'), url('./static/Mulish-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Light';
  font-weight: 300;
  font-display: swap;
  src: url('./static/mulish-v13-latin-300.woff2') format('woff2'), url('./static/Mulish-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'LightItalic';
  font-weight: 300;
  font-display: swap;
  src: url('./static/mulish-v13-latin-300italic.woff2') format('woff2'),
    url('./static/Mulish-LightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'ExtraLight';
  font-weight: 200;
  font-display: swap;
  src: url('./static/mulish-v13-latin-200.woff2') format('woff2'), url('./static/Mulish-ExtraLight.ttf') format('ttf');
}

@font-face {
  font-family: 'ExtraLightItalic';
  font-weight: 200;
  font-display: swap;
  src: url('./static/mulish-v13-latin-200italic.woff2') format('woff2'),
    url('./static/Mulish-ExtraLightItalic.ttf') format('ttf');
}
