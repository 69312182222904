.card {
  border-radius: 8px;
  box-shadow: $default-box-shadow;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
