@import '../../../../scss/bhStyles/variables.module.scss';

.bs-radio {
  display: inline-flex;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    text-align: start;

    &:hover {
      span.bs-radio-wrapper {
        span.bs-radio-inner {
          background-color: $semi-dark-gray;
        }
      }
    }

    span.bs-radio-wrapper {
      position: relative;
      width: $spacing-5;
      height: $spacing-5;
      margin-right: $spacing-2;
      display: inline-flex;

      input.bs-radio-input {
        opacity: 0;
        width: $spacing-5;
        height: $spacing-5;
        margin: 0;
        border: none;
      }

      span.bs-radio-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $medium-gray;
        mask: url('./radio-button-off.svg') no-repeat;
        mask-size: contain;
      }
    }
  }

  &.bs-radio-checked {
    label {
      &:hover {
        span.bs-radio-wrapper {
          span.bs-radio-inner {
            background-color: $primary-dark;
          }
        }
      }

      span.bs-radio-wrapper {
        span.bs-radio-inner {
          background-color: $primary-regular;
          mask: url('./radio-button-on.svg') no-repeat;
          mask-size: contain;
        }
      }
    }
  }

  &.bs-radio-disabled {
    label {
      cursor: not-allowed;

      &:hover {
        span.bs-radio-wrapper {
          span.bs-radio-inner {
            background-color: $semi-light-gray;
          }
        }
      }

      span.bs-radio-wrapper {
        span.bs-radio-inner {
          background-color: $semi-light-gray;
        }
      }
    }
  }
}
