.search {
  .container {
    margin-top: 10px;
  }

  button {
    width: 100%;
    div {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      text-transform: capitalize;
      i {
        margin-right: 0;
      }
    }
  }

  .noUi-target {
    height: 6px;
    background: #949494;
    border: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    .noUi-connect {
      background: $primary-color;
    }

    .noUi-handle {
      right: -17px;
      top: -7px;
      border-radius: 100px;
      width: 20px;
      height: 20px;
      border: 0 solid transparent;
      box-shadow: inset 0 0 1px #fff, inset 0 1px 7px $primary-color, 0 3px 6px -3px $primary-color;
      background-color: $primary-color;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.dashboard-desktop {
  .search {
    .three-col-input {
      display: flex;
      justify-content: center;
      width: 100%;
      .kenteken-input {
        display: inline-block;
        .row {
          padding-bottom: 2px;
          margin-bottom: 0;
          margin-top: 12px;
        }
      }
    }

    .filter-inputs {
      display: flex;
      &:after {
        display: none;
      }
    }

    .filters-label {
      position: absolute;
      margin-top: -17px;
      margin-left: 30px;
      background-color: white;
      padding: 5px;
      color: $primary-color;
    }
  }
}
