.season {
  display: flex;
  .col {
    border: 1px solid $grey-normal;
    cursor: pointer;
    color: #949494;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    img {
      max-height: 2rem;
      height: 2rem;
      filter: invert(69%) sepia(0%) saturate(1206%) hue-rotate(142deg) brightness(86%) contrast(87%);
    }
    &:first-child {
      border-radius: 8px 0px 0px 8px;
      border-right: transparent;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
      border-left: transparent;
    }
    &.selected {
      background-color: lighten($primary-color, 48%);
      border-color: $primary-color;
      color: $primary-color;
    }

    &.selected img {
      filter: invert(32%) sepia(99%) saturate(2468%) hue-rotate(204deg) brightness(90%) contrast(95%);
    }
  }
}
