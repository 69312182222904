@import '../../../../scss/bhStyles/variables.module.scss';

.main {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.divider {
  margin: $spacing-1 0;
  grid-column-start: 1;
  grid-column-end: 3;
}

.key {
  color: $semi-dark-gray;
}
.value {
  text-align: end;
  @media screen and (max-width: $breakpoint-large) {
    font-size: $text-font-size-16;
  }
}
