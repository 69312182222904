// Primary colors
$primary-regular: #136ce2 !default;
$primary-xxd: #062044 !default;
$primary-xd: #0b4188 !default;
$primary-dark: #0f56b5 !default;
$primary-light: #4289e8 !default;
$primary-xl: #79adf4 !default;
$primary-xxl: #e7f0fc !default;
$on-primary: #ffffff !default;

// Secondary colors
$secondary-regular: #75c42e !default;
$secondary-xxd: #233b0e !default;
$secondary-xd: #46761c !default;
$secondary-dark: #46761c !default;
$secondary-light: #90d651 !default;
$secondary-xl: #b3e388 !default;
$secondary-xxl: #f1fae9 !default;
$on-secondary: #ffffff !default;

// Tertiary colors
$tertiary-regular: #00a9ce !default;
$tertiary-xxd: #00333e !default;
$tertiary-xd: #005d71 !default;
$tertiary-dark: #007690 !default;
$tertiary-light: #1bd6ff !default;
$tertiary-xl: #58e1ff !default;
$tertiary-xxl: #e1faff !default;
$on-tertiary: #ffffff !default;

// Grays
$white: #ffffff !default;
$extra-light-gray: #fafafa !default;
$light-gray: #f2f2f2 !default;
$semi-light-gray: #d9d9d9 !default;
$medium-gray: #acacac !default;
$semi-dark-gray: #727272 !default;
$dark-gray: #434343 !default;
$semi-black: #262626 !default;
$pure-black: #000000 !default;

// Success
$success-regular: #75c42e !default;
$success-xxd: #233b0e !default;
$success-xd: #46761c !default;
$success-dark: #46761c !default;
$success-light: #90d651 !default;
$success-xl: #b3e388 !default;
$success-xxl: #f1fae9 !default;
$on-success: #ffffff !default;

// Warning
$warning-regular: #ffb31a !default;
$warning-xxd: #543800 !default;
$warning-xd: #9b6700 !default;
$warning-dark: #c58300 !default;
$warning-light: #ffc653 !default;
$warning-xl: #ffd581 !default;
$warning-xxl: #fff7e8 !default;
$on-warning: #ffffff !default;

// Alert
$alert-regular: #cc3d3d !default;
$alert-xxd: #3f1111 !default;
$alert-xd: #731e1e !default;
$alert-dark: #932727 !default;
$alert-light: #d96e6e !default;
$alert-xl: #e39494 !default;
$alert-xxl: #faecec !default;
$on-alert: #ffffff !default;

// Alpha
$alpha-black-5: #0000000c !default;
$alpha-black-10: #00000019 !default;
$alpha-black-15: #00000026 !default;
$alpha-black-55: #0000008c !default;
$alpha-white-5: #ffffff0c !default;
$alpha-white-10: #ffffff19 !default;
$alpha-white-15: #ffffff26 !default;
$alpha-white-55: #ffffff8c !default;

:export {
  primaryRegular: $primary-regular;
  primaryXxd: $primary-xxd;
  primaryXd: $primary-xd;
  primaryDark: $primary-dark;
  primaryLight: $primary-light;
  primaryXl: $primary-xl;
  primaryXxl: $primary-xxl;
  onPrimary: $on-primary;

  // Secondary colors
  secondaryRegular: $secondary-regular;
  secondaryXxd: $secondary-xxd;
  secondaryXd: $secondary-xd;
  secondaryDark: $secondary-dark;
  secondaryLight: $secondary-light;
  secondaryXl: $secondary-xl;
  secondaryXxl: $secondary-xxl;
  onSecondary: $on-secondary;

  // Tertiary colors
  tertiaryRegular: $tertiary-regular;
  tertiaryXxd: $tertiary-xxd;
  tertiaryXd: $tertiary-xd;
  tertiaryDark: $tertiary-dark;
  tertiaryLight: $tertiary-light;
  tertiaryXl: $tertiary-xl;
  tertiaryXxl: $tertiary-xxl;
  onTertiary: $on-tertiary;

  // Grays
  white: $white;
  extraLightGray: $extra-light-gray;
  lightGray: $light-gray;
  semiLightGray: $semi-light-gray;
  mediumgray: $medium-gray;
  semiDarkGray: $semi-dark-gray;
  darkGray: $dark-gray;
  semiBlack: $semi-black;
  pureBlack: $pure-black;

  // Success
  successRegular: $success-regular;
  successXxd: $success-xxd;
  successXd: $success-xd;
  successDark: $success-dark;
  successLight: $success-light;
  successXl: $success-xl;
  successXxl: $success-xxl;
  onSuccess: $on-success;

  // Warning
  warningRegular: $warning-regular;
  warningXxd: $warning-xxd;
  warningXd: $warning-xd;
  warningDark: $warning-dark;
  warningLight: $warning-light;
  warningXl: $warning-xl;
  warningXxl: $warning-xxl;
  onWarning: $on-warning;

  // Alert
  alertRegular: $alert-regular;
  alertXxd: $alert-xxd;
  alertXd: $alert-xd;
  alertDark: $alert-dark;
  alertLight: $alert-light;
  alertXl: $alert-xl;
  alertXxl: $alert-xxl;
  onAlert: $on-alert;

  // Alpha
  alphaBlack5: $alpha-black-5;
  alphaBlack10: $alpha-black-10;
  alphaBlack15: $alpha-black-15;
  alphaBlack55: $alpha-black-55;
  alphaWhite5: $alpha-white-5;
  alphaWhite10: $alpha-white-10;
  alphaWhite15: $alpha-white-15;
  alphaWhite55: $alpha-white-55;
}
