@import '../../../../scss/bhStyles/variables.module.scss';

.bs-form-select-dropdown {
  &-no-results {
    display: flex;
    cursor: auto;
    align-items: center;
    height: $spacing-10;
    width: 100%;
    padding: 0 $spacing-2;
    color: $semi-dark-gray;
    user-select: none;
  }

  &-root {
    &-enter {
      &-active {
        .bs-dropdown {
          animation: dropdown-open $animation-easing-regular $animation-speed-fast-1;
        }
      }
    }

    &-exit {
      .bs-dropdown {
        opacity: 0;
      }

      &-active {
        .bs-dropdown {
          animation: dropdown-close $animation-easing-regular $animation-speed-fast-1;
        }
      }
    }
  }
}

@keyframes dropdown-open {
  0% {
    opacity: 0;
    top: -$spacing-4;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes dropdown-close {
  0% {
    opacity: 1;
    top: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    top: -$spacing-4;
  }
}
