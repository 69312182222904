@import '../../../../scss/bhStyles/variables.module.scss';

.bs-dropdown-item {
  color: $semi-black;
  fill: $semi-dark-gray;
  box-sizing: border-box;

  &-selected {
    color: $primary-regular;
    fill: $primary-regular;
    font-family: Medium, sans-serif;
  }
}
