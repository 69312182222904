@import '../../../../scss/bhStyles/variables.module.scss';
@import './form-input-text-adornment';

@mixin form-input-infield-label() {
  .bs-form-input-label-wrapper {
    width: 100%;
    position: relative;

    &:focus-within {
      outline: transparent;
    }

    .bs-form-label {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.625rem 0 0 $spacing-3;
      color: $semi-dark-gray;
      transition: font-size $animation-speed-regular $animation-easing-regular,
        margin $animation-speed-regular $animation-easing-regular;
      font-family: Regular, sans-serif;

      &-focused {
        color: $primary-regular;
      }

      &-shrink {
        margin: 0 0 0 $spacing-3;
        font-size: $text-font-size-12 !important;
        font-family: Semibold, sans-serif;

        &.bs-form-label-disabled {
          color: $semi-dark-gray;
        }
      }
    }

    .bs-form-input-wrapper {
      display: flex;
      margin-top: $spacing-4;
      outline: transparent;

      @include form-input-text-adornment;

      .bs-form-input::placeholder {
        color: transparent;
      }

      .bs-form-input-shrink {
        &::placeholder {
          color: $medium-gray;
        }
      }
    }
  }

  .bs-form-label-error {
    color: $alert-regular !important;
  }

  .bs-form-label-success {
    color: $success-regular !important;
  }
}
