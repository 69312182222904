@import '../../../scss//variables';
.bs-fullscreen-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000cc 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 5;

  svg {
    fill: $white !important;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &-loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-header {
    padding: 16px;
    display: flex;
    justify-content: flex-end;

    p {
      font-weight: 700;
      color: white;
      font-size: 20px;
      margin: 0;
    }

    @media screen and (max-width: 1023px) {
      p {
        display: none;
      }
    }
    @media screen and (min-width: 1024px) {
      padding: 32px;
      padding-bottom: 0;
      justify-content: space-between;
    }
  }

  &-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 60px;
    margin-bottom: 40px;
    flex: 1;
    padding: 0 16px;

    .wrapper {
      width: 100%;
      height: calc(100vh - 262px);
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      overflow: hidden;
      .img-content {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        transition: transform 0.3s ease;
        display: flex;
        justify-content: center;
        margin-left: -100%;
      }

      div:first-of-type {
        margin-left: 0;
      }

      img {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
        border-radius: 8px !important;
      }
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 80px;
      margin-top: 16px;
      padding: 0 32px;

      .wrapper {
        height: calc(100vh - 270px);
      }
    }

    .left-icon {
      transform: rotate(180deg);
    }
  }

  &-mobile-contols {
    display: flex;
    align-items: center;
    padding: 4px 60px;
    margin-bottom: 36px;
    p {
      color: white;
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      text-align: center;
      margin: 0;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &-preview {
    width: fit-content;
    box-sizing: border-box;
    min-width: 100%;

    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    column-gap: 8px;

    will-change: transform;

    @media screen and (min-width: 1024px) {
      column-gap: 20px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.bs-thumbnail {
  border-radius: 4px;
  width: 72px;
  height: 48px;
  flex-shrink: 0;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  &-active {
    border: 2px solid $white;
  }

  @media screen and (min-width: 1024px) {
    width: 120px;
    height: 80px;
  }
}
