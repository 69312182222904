@import '../../../../scss/bhStyles/variables.module.scss';
@import '../FormInputBorder/FormInputBorder';
@import '../Radio/Radio';
@import '../Checkbox/Checkbox';

.bs-selectable {
  width: 100%;
  padding: $spacing-4 $spacing-3;
  @include form-input-border-base();
  @include form-input-border-status();
  transition: $animation-easing-regular $animation-speed-fast-1;
  box-sizing: border-box;
  min-height: unset;

  .bs-checkbox-inner {
    width: 100% !important;
    height: initial !important;
    padding-left: 0 !important;
    &::after {
      transform: scale(1) !important;
      width: 100% !important;
      height: initial !important;
      margin-top: 0 !important;
      border-radius: $border-radius-xs !important;
    }
    &::before {
      display: none !important;
    }
  }

  &.bs-checkbox-checked {
    .bs-checkbox-label {
      font-family: SemiBold, sans-serif;
      color: $primary-regular;
    }
  }

  .bs-checkbox-label {
    font-family: Regular, sans-serif;
    font-size: $text-font-size-16;
    margin: 0 auto;
  }

  &-size {
    &-small {
      height: $spacing-8;
    }
    &-medium {
      height: $spacing-10;
    }
    &-large {
      height: $spacing-15;
    }
  }

  &:active {
    transform: scale(0.97);
  }

  &.bs-selectable-disabled {
    &:active {
      transform: scale(1);
    }

    &.bs-checkbox-checked::after {
      border-color: $medium-gray;
    }

    .bs-checkbox-label {
      color: $medium-gray;
      .bs-checkbox-wrapper {
        &::after {
          cursor: not-allowed;
        }
      }
    }
  }

  // elevation styling
  &-elevation-shadow {
    box-shadow: $shadow-small;

    &::after {
      border-color: transparent;
    }
  }

  &-elevation-none {
    &::after {
      border-color: transparent;
    }
  }

  // borderRadius radius styling
  &-border-small {
    border-radius: $border-radius-small;
  }

  &-border-medium {
    border-radius: $border-radius-medium;

    &::after {
      border-radius: $border-radius-medium;
    }
  }

  &.bs-checkbox-checked {
    &::after {
      border-width: 2px;
      border-color: $primary-regular;
    }

    // selectBackground styling
    &.bs-selectable-background {
      background-color: $primary-xxl;
    }
  }

  //basic appearance styling
  .bs-checkbox-label {
    font-family: Regular, sans-serif;
    color: $semi-black;
    user-select: none;
    display: flex;
    align-items: center;
    // checkbox click overlay
    .bs-checkbox-wrapper {
      min-width: $spacing-5;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}

// checkbox appearance styling
.bs-selectable-appearance-checkbox.bs-selectable.bs-checkbox-checked {
  &:hover {
    .bs-checkbox-inner {
      &::after {
        background-color: $primary-dark;
      }
    }
  }
}

// radio appearance styling
.bs-selectable-appearance-radio.bs-selectable {
  .bs-checkbox-label {
    .bs-checkbox-wrapper {
      width: $spacing-5;
      height: $spacing-5;
      border: none;
      &:hover {
        .bs-checkbox-inner {
          border-color: $semi-dark-gray;
          &.bs-checkbox-inner-checked,
          &.bs-checkbox-inner-indeterminate {
            border-color: $primary-dark;
            &::before {
              background-color: $primary-dark;
            }
          }
        }
      }
      .bs-selectable-input {
        display: none;
      }
      .bs-checkbox-inner {
        box-sizing: border-box;
        border: 2px solid $medium-gray;
        border-radius: $border-radius-full;
        &::before {
          content: '';
          position: absolute;
          top: 2px;
          bottom: 2px;
          left: 2px;
          right: 2px;
          border-radius: $border-radius-full;
          background-color: transparent;
        }
        &::after {
          display: none;
        }
        &.bs-checkbox-inner-checked,
        &.bs-checkbox-inner-indeterminate {
          border-color: $primary-regular;
          &::before {
            content: '';
            background-color: $primary-regular;
          }
        }
      }
    }
  }
}

// none appearance styling
.bs-selectable-appearance-none.bs-selectable {
  .bs-checkbox-label {
    column-gap: 0;
    .bs-checkbox-wrapper {
      min-width: 0;
      opacity: 0;
      margin: 0;
      width: 0;
      border: none;
    }
  }
}
