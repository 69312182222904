@media print {
  body * {
    visibility: hidden;
  }

  #tyreLabelContent,
  #tyreLabelContent * {
    visibility: visible;
    gap: 0%;
  }

  .nlWrapper {
    background-color: black;
    -webkit-print-color-adjust: exact;
  }

  .ml-9 {
    margin-left: 9px;
  }

  .ml-7 {
    margin-left: 7px;
  }

  .ml-6 {
    margin-left: 6px;
  }

  .ml-4 {
    margin-left: 4px;
  }

  .printTopCards {
    margin-top: -100px !important;
  }

  .printBottomCards {
    margin-top: 130px !important;
    margin-bottom: -135px !important;
  }

  @page {
    size: landscape;
  }
}
