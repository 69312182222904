@import '../../../../scss/bhStyles/variables.module.scss';

.bs-dropdown-item-base {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $spacing-10;
  width: 100%;
  border-radius: $border-radius-small;
  padding: 0 $spacing-2;
  user-select: none;
  box-sizing: border-box;
  transition: $animation-speed-fast-1 $animation-easing-regular;

  &-hover:hover,
  &-focussed {
    background-color: $primary-xxl;
  }

  &:active {
    transform: scale(0.97);
  }
}
