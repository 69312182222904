// 1. Colors
// ==========================================================================

$primary-color: #136ce2;
$primary-color-contact: #e7f0fc;
$primary-color-contact-hover: #f5f9ff;
$primary-color-contact-hover-content: #0b4188;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;
$popup-background: #262626;
$progress-bar-grey: #e6e6e6;

$variant-color: #f1fae9;
$variant-icon-color: #75c42e;

$secondary-color: #28d9d4;
$success-color: #75c42e;
$warning-color: #ffd033;
$error-color: #ff3d00;
$link-color: color('light-blue', 'darken-1') !default;

$white: #ffffff;
$yellow-light: #ffd033;
$yellow-dark: #cc9d00;
$grey-light: #f7f7f7;
$grey-normal: #949494;
$grey-dark: #262626;

$default-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

// Transitions
$transition-time-default: 0.2s;

// 20. Typography
// ==========================================================================

$font-stack: 'Mulish', sans-serif;

// buttons
$button-raised-background: $primary-color;
$button-radius: 8px;

// dropdown
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $grey-dark;
$dropdown-item-height: 50px !default;

// 13. Navigation Bar
// ==========================================================================

$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
$navbar-font-color: $primary-color;
$navbar-brand-font-size: 2.1rem !default;

$navbar-background-color: $white;

:export {
  primaryColor: $primary-color;
  primaryColorContact: $primary-color-contact;
  primaryColorContactHover: $primary-color-contact-hover;
  primaryColorContactHoverContent: $primary-color-contact-hover-content;
  primaryColorLight: $primary-color-light;
  primaryColorDark: $primary-color-dark;
  popupBackground: $popup-background;
  progressBarGrey: $progress-bar-grey;
  variantColor: $variant-color;
  variantIconColor: $variant-icon-color;
  secondary-color: $secondary-color;
  success-color: $success-color;
  warning-color: $warning-color;
  error-color: $error-color;
  link-color: $link-color;
  white: $white;
  yellow-light: $yellow-light;
  yellow-dark: $yellow-dark;
  grey-light: $grey-light;
  greyNormal: $grey-normal;
  grey-dark: $grey-dark;
  default-box-shadow: $default-box-shadow;
  transition-time-default: $transition-time-default;
  font-stack: $font-stack;
  button-raised-background: $button-raised-background;
  button-radius: $button-radius;
  dropdown-bg-color: $dropdown-bg-color;
  dropdown-hover-bg-color: $dropdown-hover-bg-color;
  dropdown-color: $dropdown-color;
  dropdown-item-height: $dropdown-item-height;
  navbar-height: $navbar-height;
  navbar-line-height: $navbar-line-height;
  navbar-height-mobile: $navbar-height-mobile;
  navbar-line-height-mobile: $navbar-line-height-mobile;
  navbar-font-size: $navbar-font-size;
  navbar-font-color: $navbar-font-color;
  navbar-brand-font-size: $navbar-brand-font-size;
  navbar-background-color: $navbar-background-color;
}
