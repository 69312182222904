.orders {
  .order {
    .shipment-status {
      font-weight: bold;
    }
    .details-link {
      padding: 0;
      display: flex;
      justify-content: flex-end;
      button {
        height: 1.4rem;
        line-height: 1.2rem;
        padding-right: 0;
      }
    }
    .col {
      padding-left: 0;
    }
  }

  .divider {
    border-bottom: 1px solid #e0e0e0;
  }
}
