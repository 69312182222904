@import '../../../../scss/bhStyles/variables.module.scss';

@mixin form-input-text-adornment() {
  .bs-form-input-adornment {
    color: transparent;
    align-items: center;
  }

  &-shrink {
    .bs-form-input-adornment {
      color: $semi-dark-gray;
    }
  }
}
