@import '../../../../scss/bhStyles/variables.module.scss';
@import './form-input-infield-label';
@import './form-input-text-adornment';

.bs-form-input-text {
  .bs-form-label {
    pointer-events: none;
  }

  &:not(&-label) {
    .bs-form-input-label-wrapper {
      width: 100%;
      .bs-form-input-wrapper {
        display: flex;
        @include form-input-text-adornment;
      }
    }
  }

  &-label {
    @include form-input-infield-label();
  }
}
