$shadow-none: none;
$shadow-xs: 0 0 6px rgba(0, 0, 0, 0.06), 0 0 3px rgba(0, 0, 0, 0.03);
$shadow-small: 0 0 8px rgba(0, 0, 0, 0.08), 0 0 4px rgba(0, 0, 0, 0.04);
$shadow-medium: 0 0 12px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.04);
$shadow-large: 0 0 18px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.05);
$shadow-xl: 0 0 24px rgba(0, 0, 0, 0.08), 0 0 14px rgba(0, 0, 0, 0.06);
$shadow-xxl: 0 0 32px rgba(0, 0, 0, 0.08), 0 0 18px rgba(0, 0, 0, 0.07);

:export {
  shadowNone: $shadow-none;
  shadowXs: $shadow-xs;
  shadowSmall: $shadow-small;
  shadowMedium: $shadow-medium;
  shadowLarge: $shadow-large;
  shadowXl: $shadow-xl;
  shadowXxl: $shadow-xxl;
}
