.address-modal-button {
  border: 1px solid $grey-normal;
  border-radius: 8px;
  padding: 10px;

  svg {
    float: right;
    margin-top: -5%;
  }
}

// all modals
.modal {
  display: flex !important;
  flex-direction: column-reverse;
  border-radius: 8px;
  opacity: 0;
  z-index: -1 !important;

  &.open {
    opacity: 1;
    z-index: 1003 !important;
    transition: opacity $transition-time-default;
  }

  .modal-content,
  .modal-footer {
    display: flex;
  }

  .modal-content h4 {
    color: $primary-color;
    font-size: $h6-fontsize;
    font-weight: bold;
    padding-top: 0px;
  }

  .modal-footer div {
    justify-content: right;
    width: 100%;
    h6 {
      margin-top: 10px;
      width: 50%;
    }
    .modal-close {
      padding-right: 10px;
      margin-top: -39px;
      svg {
        color: $grey-dark;
      }
    }
  }

  .address-row {
    padding: 0;
    margin-bottom: 0;
    .col {
      padding: 0;
      margin-bottom: 0;
      margin-top: 3px;
    }
    .s5 {
      padding-left: 10px;
    }
  }
}
