@import '../../../../scss/bhStyles/variables.module.scss';

.bs-attention-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: $spacing-4;
  border-radius: $border-radius-small;
  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $spacing-2;
    &-close {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacing-2;
    }
  }
  &-icon {
    display: flex;
    align-items: flex-end;

    .bs-svg-icon {
      height: $spacing-5;
    }
  }

  &-no-header {
    display: flex;

    &-icon-wrapper {
      height: $spacing-5;

      .bs-svg-icon {
        height: $spacing-5;
      }
    }
  }

  &-button-wrapper {
    .bs-btn-icon {
      .bs-svg-icon {
        fill: $semi-dark-gray !important;
        margin-right: 0;
      }
    }
  }

  // variants
  &-primary {
    background-color: $primary-xxl;
    .bs-svg-icon {
      fill: $primary-regular;
    }
  }
  &-neutral {
    background-color: $light-gray;
    .bs-svg-icon {
      fill: $primary-regular;
    }
  }
  &-alert {
    background-color: $alert-xxl;
    .bs-svg-icon {
      fill: $alert-regular;
    }
  }
  &-success {
    background-color: $success-xxl;
    .bs-svg-icon {
      fill: $success-regular;
    }
  }
  &-warning {
    background-color: $warning-xxl;
    .bs-svg-icon {
      fill: $warning-regular;
    }
  }

  .bs-svg-icon {
    width: 20px;
    margin-right: $spacing-2;
  }
}
