.customScrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    z-index: 10;
  }
  &::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 100vw;
    margin-block: 21.5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #136ce2;
    border-radius: 100vw;
  }
}

.hideScrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0px;
}
