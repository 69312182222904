@import '../../../../scss/bhStyles/variables.module.scss';

.bs-chip-button {
  display: inline-flex;
  border-radius: $border-radius-small;
  border: 1px $semi-light-gray solid;
  align-items: center;
  padding: 0 $spacing-2;
  height: $spacing-8;
  white-space: nowrap;

  &:hover {
    .bs-chip-button-cross {
      fill: $alert-regular;
    }
  }

  & &-cross {
    fill: $semi-dark-gray;
    height: $spacing-5;
    width: $spacing-5;
    margin-left: $spacing-1;
  }

  &-pointer {
    cursor: pointer;
  }

  &.bs-chip-button-disabled {
    border-color: $semi-light-gray;
    background-color: $light-gray;
    cursor: not-allowed;
    color: $semi-dark-gray;
  }
}
