@import '../../../../scss/bhStyles/variables.module.scss';

@mixin button-color-filled($regular, $hover, $active) {
  background-color: $regular;
  color: $on-primary;

  &:before {
    display: none;
  }

  .bs-svg-icon {
    fill: $on-primary;
  }

  &:hover {
    background-color: $hover;
  }

  &:active {
    background-color: $active;
  }

  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    &:disabled {
      background-color: $regular !important;
    }
  }
}

@mixin button-color-filled-disabled() {
  &:disabled {
    background-color: $semi-light-gray !important;
  }
}

@mixin button-color-hollow($regular, $hover, $active) {
  background-color: transparent;
  color: $regular;

  .bs-svg-icon {
    fill: $regular;
  }

  &:before {
    border: 0.0625rem solid $regular;
  }

  &:hover {
    color: $hover;

    .bs-svg-icon {
      fill: $hover;
    }

    &:before {
      border: 0.125rem solid $hover;
    }
  }

  &:active {
    color: $active;

    .bs-svg-icon {
      fill: $active;
    }

    &:before {
      border: 0.125rem solid $active;
    }
  }

  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    &:before {
      border-color: $regular !important;
    }
    circle {
      stroke: $regular;
    }
  }
}

@mixin button-color-hollow-disabled() {
  &:disabled {
    color: $medium-gray !important;

    &:before {
      border: 0.0625rem solid $medium-gray !important;
    }

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }
}

@mixin button-color-tinted($regular, $hover, $active, $background) {
  background-color: $background;
  color: $regular;

  &:before {
    border: 0.125rem solid transparent;
  }

  .bs-svg-icon {
    fill: $regular;
  }

  &:hover {
    color: $hover;

    .bs-svg-icon {
      fill: $hover;
    }

    &:before {
      border: 0.125rem solid $hover;
    }
  }

  &:active {
    color: $active;

    .bs-svg-icon {
      fill: $active;
    }

    &:before {
      border: 0.125rem solid $active;
    }
  }

  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    background-color: $background;
    circle {
      stroke: $regular;
    }
  }
}

@mixin button-color-tinted-disabled() {
  &:disabled {
    background: $light-gray;
    color: $medium-gray !important;

    &:before {
      border: 0.125rem solid transparent !important;
    }

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }
}

@mixin button-color-clear($regular, $hover, $active) {
  background: transparent;
  color: $regular;

  &:before {
    border: none;
  }

  .bs-svg-icon {
    fill: $regular;
  }

  &:hover {
    color: $hover;

    .bs-svg-icon {
      fill: $hover;
    }
  }

  &:active {
    color: $active;

    .bs-svg-icon {
      fill: $active;
    }
  }
  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    circle {
      stroke: $regular;
    }
  }
}

@mixin button-color-clear-disabled() {
  &:disabled {
    color: $medium-gray !important;

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }
}

@mixin button-color-ghost($regular, $hover, $active) {
  background: transparent;
  color: $regular;

  &:before {
    border: 0.125rem solid transparent;
  }

  .bs-svg-icon {
    fill: $regular;
  }

  &:hover {
    background: $alpha-black-5;
    color: $hover;

    .bs-svg-icon {
      fill: $hover;
    }
  }

  &:active {
    background: $alpha-black-15;
    color: $active;

    .bs-svg-icon {
      fill: $active;
    }
  }
  &.bs-btn-is-loading,
  &.bs-btn-icon-is-loading {
    &:disabled {
      background-color: transparent !important;
    }
    circle {
      stroke: $regular;
    }
  }
}

@mixin button-color-ghost-disabled() {
  &:disabled {
    background: $light-gray !important;
    color: $medium-gray !important;

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }
}

@mixin button-color-link($regular, $hover, $active) {
  background: transparent;
  color: $regular;

  &:before {
    border: none;
  }

  .bs-svg-icon {
    fill: $regular;
  }

  &:hover {
    color: $hover;

    &:before {
      border: 0 solid transparent;
      transition: none;
    }

    .bs-svg-icon {
      fill: $hover;
    }
  }

  &:active {
    color: $active;

    .bs-svg-icon {
      fill: $active;
    }
  }
}

@mixin button-color-link-disabled() {
  &:disabled {
    color: $medium-gray !important;
    text-decoration: none !important;

    .bs-svg-icon {
      fill: $medium-gray;
    }
  }
}
