@import '../../../../scss/bhStyles/variables.module.scss';
@import '../../../../scss/variables';

$license-plate-nl-yellow: #fab336;
$license-plate-nl-green: #14b3a4;
$license-plate-eu-stars: #fab336;
$license-plate-eu-background: #0072ce;

.bs-form-input-license-plate {
  &.bs-form-input-license-plate-green {
    .bs-form-input-license-plate-inner {
      background-color: $license-plate-nl-green;

      .bs-form-input {
        background-color: $license-plate-nl-green;
      }
    }
  }
  &-success {
    .bs-svg-icon {
      fill: $success-regular;
    }
  }
  &-error {
    .bs-svg-icon {
      fill: $alert-regular;
    }
  }

  .bs-form-input-license-plate-inner {
    position: relative;
    background-color: $license-plate-nl-yellow;
    overflow: hidden;

    &.bs-form-input-border-error {
      border-color: $alert-regular !important;
    }

    .bs-form-input {
      text-align: center;
      background-color: $license-plate-nl-yellow;
      font-size: $heading-font-size-32;
      font-family: $font-stack !important;
      padding-left: $spacing-10;
      height: fit-content;
      color: $semi-black;
      margin: 0;
      letter-spacing: 0.25rem;
      border-bottom: none;

      &::placeholder {
        color: $semi-black;
        opacity: $opacity-heavy;
        letter-spacing: 0;
      }

      &.bs-form-input-error {
        color: $semi-black;

        &::placeholder {
          color: $alert-regular;
        }
      }
    }

    .bs-form-input-license-plate-icon-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: $spacing-10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: $spacing-3;
      background-color: $license-plate-eu-background;

      .eu-stars {
        fill: $license-plate-eu-stars;
      }

      .country-id {
        fill: $white;
      }
    }

    &.bs-form-input-license-border.bs-form-input-border-success {
      border-color: $semi-black;

      &:hover {
        border-color: $semi-black;
      }
    }
  }

  .bs-form-input-license-plate-success-wrapper {
    display: flex;
    align-items: center;
    column-gap: $spacing-4;
    border: $semi-light-gray 1px solid;
    padding: $spacing-3 $spacing-4;
    border-radius: $border-radius-small;
    max-height: $spacing-15;
    margin-top: $spacing-3;

    .bs-form-input-license-plate-text-wrapper {
      width: 100%;
      overflow: hidden;
      .bs-tg-text {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .bs-form-input-license-plate-inner.bs-form-input-border-medium {
    .bs-form-input {
      font-size: $heading-font-size-28;
      padding-left: $spacing-8;
    }
    .bs-form-input-license-plate-icon-wrapper {
      row-gap: $spacing-1;
      width: $spacing-8;
      .bs-svg-icon {
        width: $spacing-4;
      }
    }
  }
  .bs-form-input-license-plate-inner.bs-form-input-border-small {
    .bs-form-input {
      font-size: $heading-font-size-20;
      padding-left: $spacing-6;
    }
    .bs-form-input-license-plate-icon-wrapper {
      row-gap: 0;
      width: $spacing-6;
      .bs-svg-icon {
        width: $spacing-3;
      }
    }
  }
  .bs-form-input-border {
    &:focus-within {
      &::after {
        border-color: $semi-black !important;
      }
    }

    &:hover {
      &::after {
        border-color: $semi-black;
      }
    }

    &::after {
      border-color: $semi-black;
    }

    &-error {
      &:focus-within {
        &::after {
          border-color: $alert-regular !important;
        }
      }

      &::after {
        border-color: $alert-regular;
      }

      &:hover {
        &::after {
          border-color: $alert-regular;
        }
      }
    }
  }
  .spinner {
    margin: 0 auto;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: none;
    -webkit-box-shadow: 0 0 0 30px $license-plate-nl-yellow inset !important;
  }
}
