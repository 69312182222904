@import './variables.module';

@mixin scroll {
  &::-webkit-scrollbar {
    z-index: 9999;
    width: calc($spacing-1 * 3.5);
    height: calc($spacing-1 * 3.5);
    background-color: inherit;
    border-radius: $border-radius-full;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray;
    margin: $spacing-2 0;
    border: $spacing-1 solid transparent;
    background-clip: padding-box;
    border-radius: $border-radius-full;
  }

  &::-webkit-scrollbar-thumb {
    background: $medium-gray;
    border: $spacing-1 solid transparent;
    background-clip: padding-box;
    border-radius: $border-radius-full;
    &:hover {
      background: $semi-dark-gray;
      background-clip: padding-box;
    }
  }
  &::-webkit-resizer {
    background-color: transparent;
    background-image: url('./scrollbar/resizer.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@mixin scroll-no-track {
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

@mixin scroll-arrow {
  &::-webkit-scrollbar-track {
    margin: 0;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 8px;
  }

  &::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url('./scrollbar/scroll-up.svg');
    background-position: bottom center;
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }

  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }

  &::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url('./scrollbar/scroll-down.svg');
    background-position: top center;
  }
}
