@import '../../../../scss/bhStyles/variables.module.scss';

.bs-form-input-adornment {
  display: flex;
  color: $semi-dark-gray;
  font-size: $text-font-size-16;

  .bs-svg-icon {
    fill: $semi-dark-gray;
    height: $spacing-6;
    width: $spacing-6;
  }

  &-start {
    margin-left: $spacing-3;
    margin-right: -$spacing-2;

    .bs-svg-icon {
      margin-right: $spacing-1;
    }
  }

  &-end {
    margin-left: -$spacing-2;
    margin-right: $spacing-3;

    .bs-svg-icon {
      margin-left: $spacing-1;
    }
  }
}
