@import '../../../scss/bhStyles/variables.module.scss';

.grid {
  display: grid;
  grid-template-columns: calc(65% - $spacing-3) 35%;
  gap: $spacing-3;
  box-sizing: content-box;
  height: fit-content;
  margin-bottom: $spacing-8;
  :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.block {
  margin-bottom: $spacing-8;
}

.button {
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 3;
  box-sizing: border-box;
}

.button-licanse {
  margin-top: $spacing-3;
}
