@import '../../../../scss/bhStyles/variables.module.scss';
@import '../../../../scss/variables';

label.bs-form-label {
  font-family: SemiBold, sans-serif;
  display: inline-block;
  margin-bottom: $spacing-2;
  color: $semi-black;
  font-size: $text-font-size-16;

  &-optional-text {
    margin-left: $spacing-2;
    color: $semi-dark-gray;
    font-family: Regular, sans-serif;
    transition: margin-left $animation-easing-in $animation-speed-regular;
  }

  &-shrink {
    .bs-form-label-optional-text {
      margin-left: $spacing-1;
    }
  }

  &-success {
    color: $success-regular !important;
  }

  &-error {
    color: $alert-regular;
  }

  &-disabled {
    color: $semi-dark-gray;
  }

  &-required {
    color: $alert-regular;
  }
}
